import React, { useEffect, useState, useRef } from "react";
// import { Redirect } from "react-router-dom";
import "./viewQuestions.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Dropdown from "../../SubComponents/Dropdown/Dropdown";
import { BASE_URL, REWRITE_URLS } from "../../config/Api";
import Axios from "axios";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "../../SubComponents/Pagination/Pagination";
import PublicIcon from "@mui/icons-material/Public";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PersonIcon from "@mui/icons-material/Person";
import SearchSuggestion from "../../SubComponents/SearchSuggestion/SearchSuggestion";
import {
  deleteQuestionAdmin,
  fetchQuestions,
  fetchQuestionsCount,
  userList,
} from "../../../api/questions";
import {
  questionMetadata,
  updateQueMetadata,
  getQueMetadata,
} from "../../../api/user";
import {
  fetchAccessLevel,
  fetchConcept,
  fetchQuestionType,
} from "../../../api/qbMasterData";
import { boardList } from "../../../api/boards";
import {
  fetchCourse,
  fetchSubject,
  fetchSubSubject,
  fetchTopic,
  fetchSubTopic,
} from "../../../api/institution";
import { fetchMedium } from "../../../api/mediums";
import { fetchObjective } from "../../../api/objective";
import { fetchTaxonomy } from "../../../api/taxonomy";
import configuration from "../../config/Config";
import { error } from "../../SubComponents/Error/error";
// import { saveAs } from 'file-saver';
import s3 from "../../config/DigitalOcean";
import { curatorList } from "../../../api/questions";
import { Player } from "@lottiefiles/react-lottie-player";
import SearchableDropdown from "../AddDetails/searchableDropdown/searchableDropdown";

const difficultyLevel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
var institution_id = 0;
var board_id = -1;
var course_id = -1;
var subject_id = -1;
var sub_subject_id = -1;
var topic_1_id = -1;
var topic_2_id = -1;
var link;
var linkFinal;
var metaData;
var taxonomy_id = -1;
// var numberOfPages = 1;

function ViewQuestions() {
  const [formData, setFormData] = useState({
    question_type_id: "",
    board_id: "",
    course_id: "",
    subject_id: "",
    sub_subject_id: "",
    topic_1_id: "",
    sub_topic_1_id: "",
    topic_2_id: "",
    sub_topic_2_id: "",
    difficulty_level: "",
    access_level_id: "",
    medium_id: "",
    question_code: "",
    from_date: "",
    to_date: "",
    added_by_user_id: localStorage.getItem("userId"),
    createdBy: "",
    curatedBy: "",
    createdByName: "",
    curatedByName: "",
    objective_id: "",
    taxonomy_id: "",
    concept_1_ids: [],
    concept_2_ids: [],
  });

  const [state, setState] = useState({
    questionsType: [],
    medium: [],
    board: [],
    course: [],
    subject: [],
    subSubject: [],
    topic: [],
    subTopic: [],
    subTopic2: [],
    accessLevel: [],
    achieverNameList: [],
    curatorNameList: [],
    objectives: [],
    taxonomies: [],
    concept_1: [],
    concept_2: [],
  });
  const [questionList, setQuestionList] = useState([]);
  const [questionView, setQuestionView] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selLink, setSelLink] = useState("");
  const [questionId, setQuestionId] = useState("");
  const [questionFileId, setQuestionFileId] = useState("");
  // const [redirect, setRedirect] = useState(false);
  const [startUpLoad, setStartUpLoad] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [offSet, setOffSet] = useState(0);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [noData, setNoData] = useState(false);
  const [selData, setSelData] = useState({});
  const initialRender = useRef(true);
  const [questionType, setQuestionType] = useState("All");
  const [achieverSearch, setAchieverSearch] = useState("");
  const [achieverSelected, setAchieverSelected] = useState("");
  const [searchSuggestion, setSearchSuggestion] = useState(false);
  const [curatorSearch, setCuratorSearch] = useState("");
  const [curatorSelected, setCuratorSelected] = useState("");
  const [curatorSuggestion, setCuratorSuggestion] = useState(false);
  console.log(questionType);
  // console.log(formData);
  // if (numberOfQuestions !== 0) {
  //   numberOfPages = Math.ceil(numberOfQuestions / 10);
  // }

  function handleChange(e, selLabel) {
    const valueSele = e.target.value;
    if (selLabel === "createdBy") {
      setAchieverSelected(valueSele);
      setAchieverSearch(valueSele);
      if (valueSele === "") {
        setFormData((prevState) => {
          return { ...prevState, createdBy: "" };
        });
      }
      // setFormData({ ...formData, [selLabel]: valueSele });
    } else if (selLabel === "curatedBy") {
      setCuratorSelected(valueSele);
      setCuratorSearch(valueSele);
      if (valueSele === "") {
        setFormData((prevState) => {
          return { ...prevState, curatedBy: "" };
        });
      }
      // setFormData({ ...formData, [selLabel]: valueSele });
    } else if (selLabel === "concept_1" || selLabel === "concept_2") {
      fetchOtherResults(e, selLabel);
    } else {
      fetchOtherResults(e, selLabel);
      setFormData((prevState) => ({
        ...prevState,
        [selLabel]: valueSele,
      }));
    }
  }

  // console.log(formData);

  const token = window.localStorage.getItem("token");
  const userId = window.localStorage.getItem("userId");
  const role = window.localStorage.getItem("role");
  const Auth = {
    headers: {
      authorization: "Bearer " + token,
    },
  };

  useEffect(() => {
    if (questionList.length !== 0) {
      // document.getElementById("serachFirstQuestion").click();
      console.log("questionList[0]", questionList[0]);
      setSelLink(questionList[0].image_path);
      setQuestionId(questionList[0].question_id);
      setQuestionFileId(questionList[0].question_file_id);
      setSelData(questionList[0]);
    }
    //eslint-disable-next-line
  }, [questionList]);

  useEffect(() => {
    async function fetchData() {
      try {
        // let getQuestionType = await Axios.get(
        //   `${BASE_URL}/qb/question_types`,
        //   Auth
        // );
        const getQuestionType = await fetchQuestionType();
        // const getAccessLevel = await Axios.get(
        //   `${BASE_URL}/qb/access_levels`,
        //   Auth
        // );
        const getAccessLevel = await fetchAccessLevel();
        // const getMediums = await Axios.get(`${BASE_URL}/mediums`, Auth);
        const getMediums = await fetchMedium();
        const getTaxonomies = await fetchTaxonomy();
        setState((prevState) => ({
          ...prevState,
          questionsType: getQuestionType.data.data,
          accessLevel: getAccessLevel.data.data,
          medium: getMediums.data.data,
          taxonomies: getTaxonomies.data.data,
        }));
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    fetchData();
    setFormData((prevState) => {
      return { ...prevState, added_by_user_id: userId };
    });
    // eslint-disable-next-line
  }, []);

  function handleRadioButtons(e) {
    // console.log(e.target.value);
    setQuestionType(e.target.value);
  }

  function handleAchieverClick(object) {
    setAchieverSelected(object.user_name);
    setSearchSuggestion(false);
    setFormData((prevState) => ({
      ...prevState,
      createdBy: object.user_id,
      createdByName: object.user_name,
    }));
    // setAchieverCodeSelected(object.achiever_code);
  }

  function handleCuratorClick(object) {
    setCuratorSelected(object.full_name);
    setCuratorSuggestion(false);
    setFormData((prevState) => ({
      ...prevState,
      curatedBy: object.curator_id,
      curatedByName: object.full_name,
    }));
    // setAchieverCodeSelected(object.achiever_code);
  }

  function handleUlClose() {
    setSearchSuggestion(false);
    setAchieverSelected("");
    setAchieverSearch("");
    setFormData((prevState) => {
      return { ...prevState, createdBy: "" };
    });
    // setAchieverCodeSelected("");
  }

  function handleUlCloseCurator() {
    setCuratorSuggestion(false);
    setCuratorSelected("");
    setCuratorSearch("");
    setFormData((prevState) => {
      return { ...prevState, curatedBy: "" };
    });
    // setAchieverCodeSelected("");
  }

  async function handleOnFocus() {
    console.log("s");
    setStartUpLoad(true);
    try {
      // const searchItems = await Axios.get(
      //   `${BASE_URL}/qb/search/users?search_text=${achieverSearch}`,
      //   Auth
      // );
      const searchItems = await userList(achieverSearch);
      console.log(searchItems.data.data);
      let searchItemSort = searchItems.data.data.sort(function (a, b) {
        var x = a.user_name.toLowerCase();
        var y = b.user_name.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      setState((prevState) => ({
        ...prevState,
        achieverNameList: searchItemSort,
      }));
      setSearchSuggestion(true);
      setStartUpLoad(false);
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      } else {
        toast.error("Server Error");
      }
      setStartUpLoad(false);
    }
    // } else {
    //   setSearchSuggestion(false);
    // }
  }
  async function handleOnFocusCurator() {
    console.log("c");
    setStartUpLoad(true);
    try {
      // const searchItems = await Axios.get(
      //   `${BASE_URL}/qb/search/users?search_text=${achieverSearch}`,
      //   Auth
      // );
      const searchItems = await curatorList(curatorSearch);
      console.log(searchItems.data.data);
      let searchItemSort = searchItems.data.data.sort(function (a, b) {
        var x = a.full_name.toLowerCase();
        var y = b.full_name.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      setState((prevState) => ({
        ...prevState,
        curatorNameList: searchItemSort,
      }));
      setCuratorSuggestion(true);
      setStartUpLoad(false);
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      } else {
        toast.error("Server Error");
      }
      setStartUpLoad(false);
    }
    // } else {
    //   setSearchSuggestion(false);
    // }
  }

  useEffect(() => {
    if (initialRender.current !== true) {
      //   async function searchItemList() {
      //     // if (achieverSearch.length > 0) {
      //     setStartUpLoad(true);
      //     try {
      //       // const searchItems = await Axios.get(
      //       //   `${BASE_URL}/qb/search/users?search_text=${achieverSearch}`,
      //       //   Auth
      //       // );
      //       const searchItems = await userList(achieverSearch);
      //       console.log(searchItems.data.data);
      //       let searchItemSort = searchItems.data.data.sort(function (a, b) {
      //         var x = a.user_name.toLowerCase();
      //         var y = b.user_name.toLowerCase();
      //         return x < y ? -1 : x > y ? 1 : 0;
      //       });
      //       setState({
      //         ...state,
      //         achieverNameList: searchItemSort,
      //       });
      //       setSearchSuggestion(true);
      //       setStartUpLoad(false);
      //     } catch (error) {
      //       if (error.response && error.response.data) {
      //         console.log(error.response.data.message);
      //         toast.error(error.response.data.message);
      //       } else {
      //         toast.error("Server Error");
      //       }
      //       setStartUpLoad(false);
      //     }
      //     // } else {
      //     //   setSearchSuggestion(false);
      //     // }
      //   }
      //   searchItemList();
      handleOnFocus();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [achieverSearch]);

  useEffect(() => {
    if (initialRender.current !== true) {
      handleOnFocusCurator();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curatorSearch]);

  //eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const previousPath = window.localStorage.getItem("previousPath");
    window.localStorage.removeItem("previousPath");
    if (previousPath === "addDetails") {
      async function fetchMetaData() {
        try {
          // const submitedMetaData = await Axios.get(
          //   `${BASE_URL}/users/${userId}/preferences/question_metadata?pref_key=view_question_defaults`,
          //   Auth
          // );
          const submitedMetaData = await getQueMetadata(
            userId,
            "view_question_defaults"
          );
          console.log(submitedMetaData.data.data[0]);
          if (submitedMetaData.data.data.length === 0) {
            // async function meta() {
            const pref = {
              pref_key: "view_question_defaults",
              pref_value_json: JSON.stringify({
                ...formData,
                questionType: questionType,
              }),
            };
            try {
              // metaData = await Axios.post(
              //   `${BASE_URL}/users/${userId}/preferences/question_metadata`,
              //   pref,
              //   Auth
              // );
              metaData = await questionMetadata(userId, pref);
              console.log(metaData);
            } catch (err) {
              if (err.response && err.response.data) {
                console.log(err.response.data.message);
                toast.error(err.response.data.message);
              } else {
                toast.error("Server Error");
              }
              setStartUpLoad(false);
            }
          }
          if (submitedMetaData.data.data.length !== 0) {
            const subData = JSON.parse(
              submitedMetaData.data.data[0].pref_value_json
            );
            console.log(subData);
            // if (subData.answer_json !== "" && subData.answer_json !== "{}") {
            setFormData((prevState) => {
              console.log(prevState);
              return {
                ...prevState,
                question_type_id: subData.question_type_id,
                board_id: subData.board_id,
                course_id: subData.course_id,
                subject_id: subData.subject_id,
                sub_subject_id: subData.sub_subject_id,
                topic_1_id: subData.topic_1_id,
                sub_topic_1_id: subData.sub_topic_1_id,
                topic_2_id: subData.topic_2_id,
                sub_topic_2_id: subData.sub_topic_2_id,
                difficulty_level: subData.difficulty_level,
                access_level_id: subData.access_level_id,
                medium_id: subData.medium_id,
                question_code: subData.question_code,
                from_date: subData.from_date,
                to_date: subData.to_date,
                added_by_user_id: userId,
                createdBy: subData.createdBy,
                objective_id: subData.objective_id,
                taxonomy_id: subData.taxonomy_id,
              };
            });
            setQuestionType(subData.questionType);
            setAchieverSelected(subData.createdByName);
            setCuratorSelected(subData.curatedByName);
            board_id = +subData.board_id || -1;
            course_id = +subData.course_id || -1;
            subject_id = +subData.subject_id || -1;
            sub_subject_id = +subData.sub_subject_id || -1;
            topic_1_id = +subData.topic_1_id || -1;
            topic_2_id = +subData.topic_2_id || -1;
            taxonomy_id = +subData.taxonomy_id || -1;
            // }
          }
        } catch (error) {
          if (error.response && error.response.data) {
            console.log(error.response.data.message);
            toast.error(error.response.data.message);
          } else {
            toast.error("Server Error");
          }
          setStartUpLoad(false);
        }
      }
      async function fetchDropdownData() {
        // setStartUpLoad(true);
        try {
          const getBoardData = await boardList();
          const getCourseData = await fetchCourse(institution_id, board_id);
          const getSubjectData = await fetchSubject(
            institution_id,
            board_id,
            course_id
          );
          const getSubSubjectData = await fetchSubSubject(
            institution_id,
            board_id,
            course_id,
            subject_id
          );
          const getTopicData = await fetchTopic(
            institution_id,
            board_id,
            course_id,
            subject_id,
            sub_subject_id
          );
          const getSubTopicData = await fetchSubTopic(
            institution_id,
            board_id,
            course_id,
            subject_id,
            sub_subject_id,
            topic_1_id
          );
          const getAccessLevelData = await fetchAccessLevel();
          const getObjective = await fetchObjective(taxonomy_id);

          setState((prevState) => {
            return {
              ...prevState,
              board: getBoardData.data.data,
              course: getCourseData.data.data,
              subject: getSubjectData.data.data,
              subSubject: getSubSubjectData.data.data,
              topic: getTopicData.data.data,
              subTopic: getSubTopicData.data.data,
              accessLevel: getAccessLevelData.data.data,
              objectives: getObjective.data.data,
            };
          });

          console.log("dropdown");
          setStartUpLoad(false);
        } catch (err) {
          if (err.response && err.response.data) {
            console.log(err.response.data.message);
            toast.error(err.response.data.message);
          } else {
            toast.error("Server Error");
          }
          setStartUpLoad(false);
        }
      }
      await fetchMetaData();
      await fetchDropdownData();
      await handleOnFocus();
      await handleOnFocusCurator();
      console.log(formData);
      // setFormData((prevState) => prevState);
      await setSearchSuggestion(false);
      await setCuratorSuggestion(false);
      //  handleSubmit();
      document.getElementById("searchQuestions").click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialRender.current !== true) {
      async function radioButton() {
        setStartUpLoad(true);
        setSubmitLoading(true);
        link = [];
        if (formData.question_type_id && +formData.question_type_id !== -1) {
          link.push(`question_type_id=${formData.question_type_id}`);
        }
        if (formData.board_id && +formData.board_id !== -1) {
          link.push(`board_id=${formData.board_id}`);
        }
        if (formData.course_id && +formData.course_id !== -1) {
          link.push(`course_id=${formData.course_id}`);
        }
        if (formData.subject_id && +formData.subject_id !== -1) {
          link.push(`subject_id=${formData.subject_id}`);
        }
        if (formData.sub_subject_id && +formData.sub_subject_id !== -1) {
          link.push(`sub_subject_id=${formData.sub_subject_id}`);
        }
        if (formData.topic_1_id && +formData.topic_1_id !== -1) {
          link.push(`topic_1_id=${formData.topic_1_id}`);
        }
        if (formData.sub_topic_1_id && +formData.sub_topic_1_id !== -1) {
          link.push(`sub_topic_1_id=${formData.sub_topic_1_id}`);
        }
        if (formData.topic_2_id && +formData.topic_2_id !== -1) {
          link.push(`topic_2_id=${formData.topic_2_id}`);
        }
        if (formData.sub_topic_2_id && +formData.sub_topic_2_id !== -1) {
          link.push(`sub_topic_2_id=${formData.sub_topic_2_id}`);
        }
        if (formData.taxonomy_id && +formData.taxonomy_id !== -1) {
          link.push(`taxonomy_id=${formData.taxonomy_id}`);
        }
        if (formData.objective_id && +formData.objective_id !== -1) {
          link.push(`objective_id=${formData.objective_id}`);
        }
        if (formData.difficulty_level && +formData.difficulty_level !== -1) {
          link.push(`difficulty_level=${formData.difficulty_level}`);
        }
        if (formData.access_level_id && +formData.access_level_id !== -1) {
          link.push(`access_level_id=${formData.access_level_id}`);
        }
        if (formData.medium_id && +formData.medium_id !== -1) {
          link.push(`medium_id=${formData.medium_id}`);
        }
        if (formData.question_code) {
          link.push(`question_code=${formData.question_code}`);
        }
        if (formData.from_date) {
          link.push(`from_date=${formData.from_date}`);
        }
        if (formData.to_date) {
          link.push(`to_date=${formData.to_date}`);
        }
        if (formData.added_by_user_id) {
          // link.push(`added_by_user_id=${formData.added_by_user_id}`);
          link.push(`user_id=${formData.added_by_user_id}`);
        }
        if (questionType === "Verified") {
          link.push(`curate_status=Curated`);
        }
        if (questionType === "Not Verified") {
          link.push(`curate_status=Not_Curated`);
        }
        if (questionType === "Rejected") {
          link.push(`reject_status=Rejected`);
        }
        if (formData.createdBy) {
          link.push(`created_by=${formData.createdBy}`);
        }
        if (formData.curatedBy) {
          link.push(`curated_by_user_id=${formData.curatedBy}`);
        }
        linkFinal = link.join("&");
        console.log(linkFinal);
        try {
          // const getRadioNumberOfQuestions = await Axios.get(
          //   `${BASE_URL}/qb/questions/count_questions?${linkFinal}`,
          //   Auth
          // );
          const getRadioNumberOfQuestions = await fetchQuestionsCount(
            linkFinal
          );
          // const getRadioQuestionList = await Axios.get(
          //   `${BASE_URL}/qb/questions/search?${linkFinal}&limit=10&offset=${offSet}`,
          //   {
          //     headers: Auth.headers,
          //   }
          // );
          const getRadioQuestionList = await fetchQuestions(linkFinal, offSet);
          console.log(getRadioNumberOfQuestions.data.data.count);
          console.log(getRadioQuestionList.data.data);
          setNumberOfQuestions(getRadioNumberOfQuestions.data.data.count);
          setQuestionList(getRadioQuestionList.data.data);
          if (getRadioNumberOfQuestions.data.data.count > 0) {
            setStartUpLoad(false);
            setSubmitLoading(false);
          } else {
            setStartUpLoad(false);
            setSubmitLoading(false);
            setNoData(true);
          }
        } catch (error) {
          if (error.response && error.response.data) {
            console.log(error.response.data.message);
            toast.error(error.response.data.message);
          } else {
            toast.error("Server Error");
          }
          setStartUpLoad(false);
          setSubmitLoading(false);
        }
      }
      setSelLink("");
      setQuestionId("");
      setQuestionFileId("");
      setSelData("");
      radioButton();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionType]);

  useEffect(() => {
    if (initialRender.current === true) {
      initialRender.current = false;
    } else {
      async function handlePagination() {
        console.log(offSet);
        // const AuthSub1 = {
        //   headers: {
        //     authorization: "Bearer " + token,
        //   },
        // };
        link = [];
        if (formData.question_type_id && +formData.question_type_id !== -1) {
          link.push(`question_type_id=${formData.question_type_id}`);
        }
        if (formData.board_id && +formData.board_id !== -1) {
          link.push(`board_id=${formData.board_id}`);
        }
        if (formData.course_id && +formData.course_id !== -1) {
          link.push(`course_id=${formData.course_id}`);
        }
        if (formData.subject_id && +formData.subject_id !== -1) {
          link.push(`subject_id=${formData.subject_id}`);
        }
        if (formData.sub_subject_id && +formData.sub_subject_id !== -1) {
          link.push(`sub_subject_id=${formData.sub_subject_id}`);
        }
        if (formData.topic_1_id && +formData.topic_1_id !== -1) {
          link.push(`topic_1_id=${formData.topic_1_id}`);
        }
        if (formData.sub_topic_1_id && +formData.sub_topic_1_id !== -1) {
          link.push(`sub_topic_1_id=${formData.sub_topic_1_id}`);
        }
        if (formData.topic_2_id && +formData.topic_2_id !== -1) {
          link.push(`topic_2_id=${formData.topic_2_id}`);
        }
        if (formData.sub_topic_2_id && +formData.sub_topic_2_id !== -1) {
          link.push(`sub_topic_2_id=${formData.sub_topic_2_id}`);
        }
        if (formData.taxonomy_id && +formData.taxonomy_id !== -1) {
          link.push(`taxonomy_id=${formData.taxonomy_id}`);
        }
        if (formData.objective_id && +formData.objective_id !== -1) {
          link.push(`objective_id=${formData.objective_id}`);
        }
        if (formData.difficulty_level && +formData.difficulty_level !== -1) {
          link.push(`difficulty_level=${formData.difficulty_level}`);
        }
        if (formData.access_level_id && +formData.access_level_id !== -1) {
          link.push(`access_level_id=${formData.access_level_id}`);
        }
        if (formData.medium_id && +formData.medium_id !== -1) {
          link.push(`medium_id=${formData.medium_id}`);
        }
        if (formData.question_code) {
          link.push(`question_code=${formData.question_code}`);
        }
        if (formData.from_date) {
          link.push(`from_date=${formData.from_date}`);
        }
        if (formData.to_date) {
          link.push(`to_date=${formData.to_date}`);
        }
        if (formData.added_by_user_id) {
          // link.push(`added_by_user_id=${formData.added_by_user_id}`);
          link.push(`user_id=${formData.added_by_user_id}`);
        }
        if (questionType === "Verified") {
          link.push(`curate_status=Curated`);
        }
        if (questionType === "Not Verified") {
          link.push(`curate_status=Not_Curated`);
        }
        if (questionType === "Rejected") {
          link.push(`reject_status=Rejected`);
        }
        if (formData.createdBy) {
          link.push(`created_by=${formData.createdBy}`);
        }
        if (formData.curatedBy) {
          link.push(`curated_by_user_id=${formData.curatedBy}`);
        }
        linkFinal = link.join("&");
        console.log(linkFinal);
        try {
          // const getQuestions = await Axios.get(
          //   `${BASE_URL}/qb/questions/search?${linkFinal}&limit=10&offset=${offSet}`,
          //   {
          //     headers: AuthSub1.headers,
          //   }
          // );
          const getQuestions = await fetchQuestions(linkFinal, offSet);
          console.log(getQuestions.data.data);
          setQuestionList(getQuestions.data.data);
          setStartUpLoad(false);
        } catch (err) {
          if (err.response && err.response.data) {
            console.log(err.response.data.message);
            toast.error(err.response.data.message);
          } else {
            toast.error("Server Error");
          }
          setStartUpLoad(false);
        }
      }
      setSelLink("");
      setQuestionId("");
      setQuestionFileId("");
      setSelData("");
      handlePagination();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offSet]);

  async function fetchOtherResults(e, selLabel) {
    if (selLabel === "question_type_id") {
      setStartUpLoad(true);
      board_id = -1;
      course_id = -1;
      subject_id = -1;
      sub_subject_id = -1;
      topic_1_id = -1;
      topic_2_id = -1;
      taxonomy_id = -1;
      setFormData((prevState) => {
        return {
          ...prevState,
          board_id: -1,
          course_id: -1,
          subject_id: -1,
          sub_subject_id: -1,
          topic_1_id: -1,
          sub_topic_1_id: -1,
          sub_topic_2_id: -1,
          topic_2_id: -1,
          taxonomy_id: -1,
        };
      });
      try {
        // const getBoard = await Axios.get(`${BASE_URL}/boards`, Auth);
        const getBoard = await boardList();
        setState({
          ...state,
          board: getBoard.data.data,
        });
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "board_id") {
      course_id = -1;
      subject_id = -1;
      sub_subject_id = -1;
      topic_1_id = -1;
      topic_2_id = -1;
      taxonomy_id = -1;
      setStartUpLoad(true);
      setFormData((prevState) => {
        return {
          ...prevState,
          course_id: -1,
          subject_id: -1,
          sub_subject_id: -1,
          topic_1_id: -1,
          sub_topic_1_id: -1,
          sub_topic_2_id: -1,
          topic_2_id: -1,
          taxonomy_id: -1,
        };
      });
      board_id = e.target.value;
      try {
        // const getCourse = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${board_id}/courses`,
        //   Auth
        // );
        const getCourse = await fetchCourse(institution_id, board_id);
        setState({
          ...state,
          course: getCourse.data.data,
        });
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "course_id") {
      subject_id = -1;
      sub_subject_id = -1;
      topic_1_id = -1;
      topic_2_id = -1;
      taxonomy_id = -1;
      setFormData((prevState) => {
        return {
          ...prevState,
          subject_id: -1,
          sub_subject_id: -1,
          topic_1_id: -1,
          sub_topic_1_id: -1,
          sub_topic_2_id: -1,
          topic_2_id: -1,
          taxonomy_id: -1,
        };
      });
      setStartUpLoad(true);
      course_id = e.target.value;
      try {
        // const getSubject = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects`,
        //   Auth
        // );
        const getSubject = await fetchSubject(
          institution_id,
          board_id,
          course_id
        );
        setState({
          ...state,
          subject: getSubject.data.data,
        });

        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "subject_id") {
      sub_subject_id = -1;
      topic_1_id = -1;
      topic_2_id = -1;
      taxonomy_id = -1;
      setFormData((prevState) => {
        return {
          ...prevState,
          sub_subject_id: -1,
          topic_1_id: -1,
          sub_topic_1_id: -1,
          sub_topic_2_id: -1,
          topic_2_id: -1,
          taxonomy_id: -1,
        };
      });
      setStartUpLoad(true);
      subject_id = e.target.value;
      try {
        // const getSubSubject = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/sub_subjects`,
        //   Auth
        // );
        const getSubSubject = await fetchSubSubject(
          institution_id,
          board_id,
          course_id,
          subject_id
        );
        setState((prevState) => ({
          ...prevState,
          subSubject: getSubSubject.data.data,
        }));

        setStartUpLoad(false);
      } catch (err) {
        console.log(err, "error while fetching data");
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "sub_subject_id") {
      topic_1_id = -1;
      topic_2_id = -1;
      taxonomy_id = -1;
      setFormData((prevState) => {
        return {
          ...prevState,
          topic_1_id: -1,
          sub_topic_1_id: -1,
          sub_topic_2_id: -1,
          topic_2_id: -1,
          taxonomy_id: -1,
        };
      });
      setStartUpLoad(true);
      sub_subject_id = e.target.value;
      try {
        // const getTopic = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/sub_subjects/${sub_subject_id}/topics`,
        //   Auth
        // );
        const getTopic = await fetchTopic(
          institution_id,
          board_id,
          course_id,
          subject_id,
          sub_subject_id
        );
        setState((prevState) => ({
          ...prevState,
          topic: getTopic.data.data,
        }));

        setStartUpLoad(false);
      } catch (err) {
        console.log(err, "error while fetching data");
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "topic_1_id") {
      topic_1_id = -1;
      taxonomy_id = -1;
      setFormData((prevState) => {
        return {
          ...prevState,
          sub_topic_1_id: -1,
          sub_topic_2_id: -1,
          topic_2_id: -1,
          taxonomy_id: -1,
        };
      });
      setStartUpLoad(true);
      topic_1_id = e.target.value;
      try {
        // const getSubTopic = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/sub_subjects/${sub_subject_id}/topics/${topic_1_id}/sub_topics`,
        //   Auth
        // );
        const getSubTopic = await fetchSubTopic(
          institution_id,
          board_id,
          course_id,
          subject_id,
          sub_subject_id,
          topic_1_id
        );
        console.log(getSubTopic.data.data);
        setState((prevState) => ({
          ...prevState,
          subTopic: getSubTopic.data.data,
        }));

        setStartUpLoad(false);
      } catch (err) {
        console.log(err, "error while fetching data");
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "sub_topic_1_id") {
      setStartUpLoad(true);
      // sub_topic_1_id = e.target.value;
      try {
        const fetchConceptList = await fetchConcept(e?.target?.value);
        const getAccessLevel = await fetchAccessLevel();
        setState((pre) => ({
          ...pre,
          concept_1: fetchConceptList?.data?.data,
          accessLevel: getAccessLevel.data.data,
        }));

        setFormData((prevState) => {
          return {
            ...prevState,
            taxonomy_id: -1,
          };
        });
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "sub_topic_2_id") {
      setStartUpLoad(true);
      // sub_topic_1_id = e.target.value;
      try {
        // const getAccessLevel = await Axios.get(
        //   `${BASE_URL}/qb/access_levels`,
        //   Auth
        // );

        const fetchConceptList2 = await fetchConcept(e?.target?.value);

        setState((pre) => ({
          ...pre,
          concept_2: fetchConceptList2?.data?.data,
        }));

        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "topic_2_id") {
      setStartUpLoad(true);
      topic_2_id = e.target.value;
      try {
        const getSubTopic2 = await Axios.get(
          `${BASE_URL}/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/sub_subjects/${sub_subject_id}/topics/${topic_2_id}/sub_topics`,
          Auth
        );
        setState({
          ...state,
          subTopic2: getSubTopic2.data.data,
        });
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "taxonomy_id") {
      setStartUpLoad(true);
      taxonomy_id = e.target.value;
      console.log(taxonomy_id);
      try {
        const getObjective = await fetchObjective(taxonomy_id);
        setState({
          ...state,
          objectives: getObjective.data.data,
        });
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
  }

  //function to search questions
  async function handleSubmit() {
    setSubmitLoading(true);
    setNoData(false);

    try {
      // Initialize the query parameters
      const queryParams = [];

      // Handle fields that can be dynamically processed
      const dynamicFields = [
        "question_type_id",
        "board_id",
        "course_id",
        "subject_id",
        "sub_subject_id",
        "topic_1_id",
        "sub_topic_1_id",
        "topic_2_id",
        "sub_topic_2_id",
        "taxonomy_id",
        "objective_id",
        "difficulty_level",
        "access_level_id",
        "medium_id",
      ];

      dynamicFields.forEach((field) => {
        if (formData[field] && +formData[field] !== -1) {
          queryParams.push(`${field}=${formData[field]}`);
        }
      });

      // Handle complex fields like `concept_1_ids` and `concept_2_ids`
      if (
        formData.concept_1_ids?.length > 0 ||
        formData.concept_2_ids?.length > 0
      ) {
        const uniqueId = [
          ...(formData.concept_1_ids || []),
          ...(formData.concept_2_ids || []),
        ];
        const conceptIds = JSON.stringify(
          uniqueId.filter((i, index) => uniqueId.indexOf(i) === index)
        );
        queryParams.push(`concept_ids=${conceptIds}`);
      }

      // Handle additional specific fields
      const additionalFields = {
        question_code: formData.question_code,
        from_date: formData.from_date,
        to_date: formData.to_date,
        user_id: formData.added_by_user_id,
        created_by: formData.createdBy,
        curated_by_user_id: formData.curatedBy,
      };

      Object.entries(additionalFields).forEach(([key, value]) => {
        if (value) {
          queryParams.push(`${key}=${value}`);
        }
      });

      // Handle question type-specific fields
      const questionTypeMapping = {
        Verified: "curate_status=Curated",
        "Not Verified": "curate_status=Not_Curated",
        Rejected: "reject_status=Rejected",
      };

      if (questionTypeMapping[questionType]) {
        queryParams.push(questionTypeMapping[questionType]);
      }

      // Create the final query string
      const linkFinal = queryParams.join("&");
      console.log(linkFinal);
      console.log(formData);

      // API calls
      const getNumberOfQuestions = await fetchQuestionsCount(linkFinal);
      const getQuestions = await fetchQuestions(linkFinal, offSet);

      const questionCount = getNumberOfQuestions.data.data.count;
      const questions = getQuestions.data.data;

      console.log(questionCount);
      console.log(questions);

      setNumberOfQuestions(questionCount);
      setQuestionList(questions);

      if (questionCount > 0) {
        setQuestionView(true);
        window.scrollTo(0, 1000);
      } else {
        setQuestionView(false);
        setNoData(true);
      }
    } catch (err) {
      setNoData(true);
      if (err.response?.data?.message) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      } else {
        toast.error("Server Error");
      }
    } finally {
      setSubmitLoading(false);
      setStartUpLoad(false);
    }
  }

  function handleClick(SelQue) {
    console.log("SelQue", SelQue);
    setSelLink(SelQue.image_path);
    setQuestionId(SelQue.question_id);
    setQuestionFileId(SelQue.question_file_id);
    setSelData(SelQue);
    // window.scrollTo(0, 2000);
  }

  async function handleEditDetails() {
    window.localStorage.removeItem("reuseId");
    setSubmitLoading(true);
    const pref = {
      pref_key: "view_question_defaults",
      pref_value_json: JSON.stringify({
        ...formData,
        questionType: questionType,
      }),
    };
    try {
      metaData = await updateQueMetadata(userId, pref);
      console.log(metaData);
      setSubmitLoading(false);
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      } else {
        toast.error("Server Error");
      }
      setSubmitLoading(false);
    }

    window.localStorage.setItem("question_id", questionId);
    window.localStorage.setItem(
      "questionFileId",
      questionFileId ? questionFileId : 0
    );
    window.localStorage.setItem("previousPath", "viewQuestion");
    window.localStorage.setItem("previousImageLink", selLink);
    window.localStorage.removeItem("formData");
    return window.open("/admin/add-details", "_blank", "noreferrer");
    // setRedirect(true);
  }

  async function handleReuse() {
    window.localStorage.setItem("question_id", questionId);
    window.localStorage.setItem("questionFileId", questionFileId);
    window.localStorage.setItem("previousPath", "viewQuestion");
    window.localStorage.setItem("previousImageLink", selLink);
    window.localStorage.removeItem("formData");
    window.localStorage.setItem("sourceQueData", JSON.stringify(selData));
    // window.localStorage.setItem("reuse", true);
    window.localStorage.setItem(
      "reuseId",
      selData.question_reuse_id !== null
        ? selData.question_reuse_id
        : selData.question_id
    );
    return window.open("/admin/add-details", "_blank", "noreferrer");
    // setRedirect(true);
  }

  // if (redirect) {
  //   // return <Redirect to="/admin/add-details" />;
  //   return window.open("/admin/add-details", "_blank", "noreferrer")
  // }

  function paginationClick(number, offSetNumber) {
    if (Math.ceil(numberOfQuestions / 10) > 1) {
      setPageNumber(+number);
      setOffSet(+offSetNumber);
      setStartUpLoad(true);
    }
  }

  function newDateFormate(oldDate) {
    var dateArray = oldDate.split("-");
    var newDate = [dateArray[1], dateArray[0], dateArray[2]].join("-");
    return new Date(newDate + "Z").toString()?.slice(0, 25);
  }
  function handleTranslate() {
    window.localStorage.setItem("translateQuestion", JSON.stringify(selData));
    window.location = "/admin/translate-questions";
  }
  async function handleDownloadSource() {
    setStartUpLoad(true);
    if (
      selData.question_group_source_file_path === null ||
      selData.question_group_source_file_path === undefined
    ) {
      toast.error("File Not Available");
    } else {
      s3.getObject(
        {
          Bucket: configuration.bucketName,
          Key: selData.question_group_source_file_path,
        },
        (err, data) => {
          if (err) {
            console.error(err);
            error(err);
            setStartUpLoad(false);
            return;
          }

          const url = URL.createObjectURL(new Blob([data.Body]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            selData.question_group_source_file_path.split("/")[2]
          );
          document.body.appendChild(link);
          link.click();
          setStartUpLoad(false);
        }
      );
    }
  }

  async function handleDelete() {
    setStartUpLoad(true);
    console.log(questionId, userId);
    try {
      const deleteQue = await deleteQuestionAdmin(questionId, userId);
      console.log(deleteQue);
      toast.success("Question delete sucessful");
      handleSubmit();
    } catch (err) {
      error(err);
      setStartUpLoad(false);
    }
  }

  // function getPosition(str, m, i) {
  //   return str.split(m, i).join(m).length;
  // }

  return (
    <div>
      {startUpLoad ? (
        <div className="loadingBackground">
          <ReactLoading type="spin" color="#68dff0" className="startLoadSpin" />
        </div>
      ) : null}
      <form>
        <div className="queContainer">
          <h1 className="viewH3">
            <ArrowForwardIosIcon className="viewH3Arrow" />
            Questions View
          </h1>
        </div>
        <div>
          <div className="searchQuestionContainer">
            <h4 className="searchQuestionHeading">Search Question</h4>
            {(role === "Curator" || role === "Admin") && (
              <div className="questionViewdropdownDiv">
                <label className="searchQuestionLabel">Created By</label>
                <SearchSuggestion
                  achieverSelected={achieverSelected}
                  // disabled={editValue}
                  handleChange={handleChange}
                  searchSuggestion={searchSuggestion}
                  dropDownData={state.achieverNameList}
                  handleUlClose={handleUlClose}
                  handleUlClick={handleAchieverClick}
                  handleOnFocus={handleOnFocus}
                  name="user_name"
                  type="createdBy"
                />
              </div>
            )}
            {(role === "Curator" || role === "Admin") && (
              <div className="questionViewdropdownDiv">
                <label className="searchQuestionLabel">Curated By</label>
                <SearchSuggestion
                  achieverSelected={curatorSelected}
                  // disabled={editValue}
                  handleChange={handleChange}
                  searchSuggestion={curatorSuggestion}
                  dropDownData={state.curatorNameList}
                  handleUlClose={handleUlCloseCurator}
                  handleUlClick={handleCuratorClick}
                  handleOnFocus={handleOnFocusCurator}
                  name="full_name"
                  type="curatedBy"
                />
              </div>
            )}
            <Dropdown
              label="Questions Type"
              options={state.questionsType}
              displayName="display_name"
              name="question_type_id"
              idName="id"
              value={formData.question_type_id}
              onChange={handleChange}
              req={false}
            >
              {" "}
            </Dropdown>
            <Dropdown
              label="Board"
              options={state.board}
              name="board_id"
              displayName="board_display_name"
              idName="board_id"
              value={formData.board_id}
              onChange={handleChange}
              req={false}
            />
            <Dropdown
              label="Course"
              options={state.course}
              name="course_id"
              displayName="display_name"
              idName="course_id"
              value={formData.course_id}
              onChange={handleChange}
              req={false}
            />
            <Dropdown
              label="Subject"
              options={state.subject}
              name="subject_id"
              displayName="display_name"
              idName="subject_id"
              value={formData.subject_id}
              onChange={handleChange}
              req={false}
            />
            <Dropdown
              label="Sub-Subject"
              options={state.subSubject}
              name="sub_subject_id"
              displayName="display_name"
              idName="sub_subject_id"
              value={formData.sub_subject_id}
              onChange={handleChange}
              req={false}
              displayEngName={"display_name_eng"}
            />
            <Dropdown
              label="Topic 1"
              options={state.topic}
              name="topic_1_id"
              displayName="display_name"
              idName="topic_id"
              value={formData.topic_1_id}
              onChange={handleChange}
              req={false}
              displayEngName={"display_name_eng"}
            />
            <Dropdown
              label="Sub-Topic 1"
              options={state.subTopic}
              name="sub_topic_1_id"
              displayName="display_name"
              idName="sub_topic_id"
              value={formData.sub_topic_1_id}
              onChange={handleChange}
              req={false}
              displayEngName={"display_name_eng"}
            />
            <SearchableDropdown
              lable="Concept 1 *"
              options={state.concept_1}
              display_name="concept_name"
              display_id="concept_id"
              value={state?.concept_1?.filter((i) =>
                formData?.concept_1_ids?.includes(i.concept_id)
              )}
              onCancel={(e) => {
                console.log(
                  e,
                  formData.concept_1_ids.filter((fill) => fill == e)
                );

                setFormData((pre) => ({
                  ...pre,
                  concept_1_ids: formData.concept_1_ids.filter(
                    (fill) => fill !== e
                  ),
                }));
              }}
              onSelect={(e) => {
                console.log(e);
                setFormData((pre) => ({
                  ...pre,
                  concept_1_ids: [...formData.concept_1_ids, e],
                }));
              }}
            />
            <Dropdown
              label="Topic 2"
              options={state.topic}
              name="topic_2_id"
              displayName="display_name"
              idName="topic_id"
              value={formData.topic_2_id}
              onChange={handleChange}
              req={false}
              displayEngName={"display_name_eng"}
            />
            <Dropdown
              label="Sub-Topic 2"
              options={state.subTopic2}
              name="sub_topic_2_id"
              displayName="display_name"
              idName="sub_topic_id"
              value={formData.sub_topic_2_id}
              onChange={handleChange}
              req={false}
              displayEngName={"display_name_eng"}
            />

            <SearchableDropdown
              lable="Concept 2"
              options={state.concept_2}
              display_name="concept_name"
              display_id="concept_id"
              value={state?.concept_2?.filter((i) =>
                formData?.concept_2_ids?.includes(i.concept_id)
              )}
              onCancel={(e) => {
                console.log(
                  e,
                  formData.concept_2_ids.filter((fill) => fill == e)
                );

                setFormData((pre) => ({
                  ...pre,
                  concept_2_ids: formData.concept_2_ids.filter(
                    (fill) => fill !== e
                  ),
                }));
              }}
              onSelect={(e) => {
                console.log(e);
                setFormData((pre) => ({
                  ...pre,
                  concept_2_ids: [...formData.concept_2_ids, e],
                }));
              }}
            />

            <Dropdown
              label="Taxonomy"
              options={state.taxonomies}
              displayName="display_name"
              name="taxonomy_id"
              idName="id"
              onChange={handleChange}
              value={formData.taxonomy_id}
              req={false}
            />
            <Dropdown
              label="Objective"
              options={state.objectives}
              displayName="objective_code"
              name="objective_id"
              idName="objective_id"
              onChange={handleChange}
              value={formData.objective_id}
              req={false}
            />
            <Dropdown
              label="Difficulty Level"
              options={difficultyLevel}
              name="difficulty_level"
              displayName=""
              value={formData.difficulty_level}
              onChange={handleChange}
              req={false}
            />
            <Dropdown
              label="Access Level"
              options={state.accessLevel}
              displayName="display_name"
              name="access_level_id"
              idName="id"
              value={formData.access_level_id}
              onChange={handleChange}
              req={false}
            />
            <Dropdown
              label="Medium"
              options={state.medium}
              name="medium_id"
              displayName="medium_name"
              idName="medium_id"
              value={formData.medium_id}
              onChange={handleChange}
              req={false}
            />
            <div className="viewdropdownDiv">
              <label className="searchQuestionLabel">Question #</label>
              <input
                className="searchQuestionSelect"
                value={formData.question_code}
                onChange={(e) => handleChange(e, "question_code")}
              ></input>
            </div>
            <div className="viewdropdownDiv">
              <label className="searchQuestionLabel">From Date</label>
              <input
                className="searchQuestionSelect"
                type="date"
                value={formData.from_date}
                onChange={(e) => handleChange(e, "from_date")}
              ></input>
            </div>
            <div className="viewdropdownDiv">
              <label className="searchQuestionLabel">To Date</label>
              <input
                className="searchQuestionSelect"
                type="date"
                value={formData.to_date}
                onChange={(e) => handleChange(e, "to_date")}
              ></input>
            </div>
          </div>
        </div>
        <div className="viewContainer">
          <button
            className="viewSearch"
            id="searchQuestions"
            type="button"
            onClick={handleSubmit}
          >
            Search
          </button>
          {submitLoading ? (
            <div className="loadingDiv">
              <ReactLoading
                type="spinningBubbles"
                color="#68dff0"
                className="reactLoadingIcon"
              />
              <h5 className="loadingText">Loading</h5>
            </div>
          ) : null}
          {noData ? (
            <div className="loadingDiv">
              <h5 className="loadingTextData">Data is not available</h5>
            </div>
          ) : null}
        </div>
      </form>
      <div>
        {questionView ? (
          <div className="compulsoryContainerViewQuestions">
            <div className="viewQuestionsH4RadioDiv">
              <h4 className="compulsoryHeading">Questions</h4>
              <div
                className="viewQuestionsRadioDiv"
                // onChange={handleRadioButtons}
              >
                <input
                  type="radio"
                  value="All"
                  name="questions"
                  checked={questionType === "All"}
                  className="viewQuestionsRadioInput"
                  onChange={handleRadioButtons}
                />{" "}
                <p className="viewQuestionsRadioP">All</p>
                <input
                  type="radio"
                  value="Verified"
                  name="questions"
                  checked={questionType === "Verified"}
                  className="viewQuestionsRadioInput"
                  onChange={handleRadioButtons}
                />{" "}
                <p className="viewQuestionsRadioP">Curated</p>
                <input
                  type="radio"
                  value="Not Verified"
                  name="questions"
                  checked={questionType === "Not Verified"}
                  className="viewQuestionsRadioInput"
                  onChange={handleRadioButtons}
                />{" "}
                <p className="viewQuestionsRadioP">Not-Curated</p>
                <input
                  type="radio"
                  value="Rejected"
                  name="questions"
                  checked={questionType === "Rejected"}
                  className="viewQuestionsRadioInput"
                  onChange={handleRadioButtons}
                />
                <p className="viewQuestionsRadioP">Rejected</p>
              </div>
            </div>
            <div className="viewQuestionsSearchDisplay">
              <div className="viewTablePagination">
                <div className="tableContainerViewQuestion">
                  <div>
                    {questionList.map((row, questionKey) => {
                      return (
                        <div key={questionKey}>
                          <div
                            className={
                              +selData.question_id === +row.question_id
                                ? "viewResultRowDivSel"
                                : "viewResultRowDiv"
                            }
                            onClick={(e) => handleClick(row)}
                            // id={questionKey === 0 ? "serachFirstQuestion" : null}
                          >
                            <div>
                              <p>{questionKey + 1 + 10 * (pageNumber - 1)}</p>
                            </div>
                            <div className="viewShortDiv">
                              <p className="viewShortP">
                                {row.short_description}
                              </p>
                              <div className="viewByLastDiv">
                                {row.access_level === "Global" && (
                                  <PublicIcon
                                    className="viewPublicIcon"
                                    fontSize="small"
                                  />
                                )}
                                {row.access_level === "Organisation" && (
                                  <AccountBalanceIcon
                                    className="viewPublicIcon"
                                    fontSize="small"
                                  />
                                )}
                                {row.access_level === "Private" && (
                                  <PersonIcon
                                    className="viewPublicIcon"
                                    fontSize="small"
                                  />
                                )}
                                <p className="viewBy">By: {row.created_by}</p>
                                <p className="viewLastUpdated">
                                  {/* Last Updated: {new Date(row.last_updated + "Z").toString()} */}
                                  Last Updated:{" "}
                                  {newDateFormate(row.last_updated)}
                                </p>
                              </div>
                              {questionType !== "Not Verified" && (
                                <div className="viewByLastDiv">
                                  <p className="viewByCurated">
                                    Curated: {row.is_curated ? "Yes" : "No"}
                                  </p>
                                  {row.is_curated === true && (
                                    <p className="viewByCurated">
                                      Curated By: {row.curated_by}
                                    </p>
                                  )}

                                  <p className="viewByCurated">
                                    Solution Exists:{" "}
                                    {row?.solution_file &&
                                    row?.solution_file !== ""
                                      ? "Yes"
                                      : row?.solution_text &&
                                        row?.solution_text !== ""
                                      ? "Text Only"
                                      : "No"}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {/* <table className="backlogTable">
                    <thead>
                      <tr>
                        <th>Sl. No.</th>
                        <th>Question #</th>
                        <th className="child2Migrate">Short Description</th>
                        <th className="child3Migrate">Created By</th>
                        <th>Verified By</th>
                        <th>Photo Path</th> 
                      </tr>
                    </thead>
                    <tbody>
                      {questionList.map((row, questionKey) => {
                        return (
                          <tr
                            key={questionKey}
                            onClick={(e) => handleClick(row)}
                          >
                            <td>{questionKey + 1}.</td>
                            <td>{row.question_id}</td>
                            <td>{row.short_description}</td>
                            <td>{row.created_by}</td>
                            <td>{row.verified_teacher}</td>
                           <td>
                          <img
                            src={row.image_path}
                            alt="UpLoaded"
                            className="backlogImage"
                          />
                        </td> 
                          </tr>
                        );
                      })}
                    </tbody>
                  </table> */}
                </div>
                <Pagination
                  paginationClick={paginationClick}
                  numberOfRows={numberOfQuestions}
                  offSet={offSet}
                  pageNumber={pageNumber}
                />
              </div>
              <div>
                {selLink !== "" ? (
                  <div className="viewQuestionDisplayDiv">
                    <p className="viewImageAbove">
                      #{selData.question_id}, Topic: {selData.topic} ,
                      Sub-topic: {selData.sub_topic}
                    </p>
                    <div className="viewImageContainer ">
                      {selLink?.endsWith(".json") ? (
                        <Player
                          autoplay
                          loop
                          src={
                            (REWRITE_URLS === "1"
                              ? configuration.digitalOceanSpacesRewrite
                              : configuration.digitalOceanSpaces) +
                            selLink?.slice(
                              configuration.digitalOceanSpaces.length
                            )
                          }
                          style={{ width: "100%", height: "450px" }}
                        />
                      ) : selLink?.endsWith(".webm") ? (
                        <video
                          controls
                          name="media"
                          style={{ width: "100%", height: "450px" }}
                        >
                          <source
                            src={
                              (REWRITE_URLS === "1"
                                ? configuration.digitalOceanSpacesRewrite
                                : configuration.digitalOceanSpaces) +
                              selLink?.slice(
                                configuration.digitalOceanSpaces.length
                              )
                            }
                            type="video/webm"
                          />
                        </video>
                      ) : (
                        <img
                          src={
                            (REWRITE_URLS === "1"
                              ? configuration.digitalOceanSpacesRewrite
                              : configuration.digitalOceanSpaces) +
                            selLink?.slice(
                              configuration.digitalOceanSpaces.length
                            )
                            // selLink.slice(getPosition(selLink, "/", 3) + 1)
                          }
                          alt="UpLoaded"
                          className="viewQuestionsBigImage"
                        />
                      )}
                    </div>
                    <div className="veiwQuestionDisplayInfo">
                      {selData.response_type === "MCQ" && (
                        <p className="viewImageBelow">
                          Answer:{" "}
                          {selData.answer.map((object, objectKey) => {
                            return objectKey === selData.answer.length - 1
                              ? object.option_text
                              : object.option_text + ", ";
                          })}
                        </p>
                      )}
                      {selData.response_type === "Yes/No" && (
                        <p className="viewImageBelow">
                          Answer: {selData.answer.correct_answer}
                        </p>
                      )}
                      {selData.response_type === "0-10" && (
                        <p className="viewImageBelow">
                          Answer: {selData.answer.correct_answer}
                        </p>
                      )}
                      {selData.response_type === "MAQ" && (
                        <p className="viewImageBelow">
                          Answer:{" "}
                          {selData.answer.map((object, objectKey) => {
                            return objectKey === selData.answer.length - 1
                              ? object.option_text
                              : object.option_text + ", ";
                          })}
                        </p>
                      )}
                      {selData.response_type === "Matching " && (
                        <p className="viewImageBelow">
                          Answer:{" "}
                          {selData.answer.map((object, objectKey) => {
                            return objectKey === selData.answer.length - 1
                              ? object.left_hand_option +
                                  "-" +
                                  object.right_hand_option
                              : object.left_hand_option +
                                  "-" +
                                  object.right_hand_option +
                                  ", ";
                          })}
                        </p>
                      )}
                      {selData.response_type === "One Word Answer" && (
                        <p className="viewImageBelow">
                          Answer: {selData.answer.correct_answer}
                        </p>
                      )}
                      {selData.response_type === "FITB" && (
                        <p className="viewImageBelow">
                          Answer: {selData.answer.correct_answer}
                        </p>
                      )}
                      {selData.response_type === "Text" && (
                        <p className="viewImageBelow">
                          Answer: {selData.answer.correct_answer}
                        </p>
                      )}
                      {(selData.response_type === "Image" ||
                        selData.response_type === "Voice" ||
                        selData.response_type === "Video") && (
                        <a
                          className="viewImageBelowFile"
                          href={selData.answer.answer_file_path}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Answer: File.
                        </a>
                      )}
                      {/* {(selData.response_type === "Image" || selData.response_type === "Voice" ||
                      selData.response_type === "Video") && (
                      <div
                        className="viewImageBelowFile"
                        onClick={(e) => downloadFile(selData.answer.answer_file_path)}
                      >
                        Answer: File.
                      </div>
                    )} */}
                      {selData.response_type === "Files" && (
                        <a
                          className="viewImageBelowFile"
                          href={selData.answer[0].answer_file_path}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Answer: File.
                        </a>
                      )}
                      <p className="viewImageBelow">
                        Reference: {selData.reference}
                      </p>
                    </div>
                    <div className="veiwQuestionDisplayInfo">
                      <p className="viewImageBelowTwo">
                        Difficulty: {selData.difficulty_level}
                      </p>
                      <p className="viewImageBelowTwo">
                        Objective: {selData.objective}
                      </p>
                    </div>
                    <div className="viewAddContainer">
                      <button
                        className="ReuseButton"
                        onClick={handleEditDetails}
                      >
                        Edit Details
                      </button>
                      <button className="ReuseButton" onClick={handleReuse}>
                        Reuse
                      </button>
                      <button className="ReuseButton" onClick={handleTranslate}>
                        Translate
                      </button>
                      {role === "Admin" ? (
                        <button className="ReuseButton" onClick={handleDelete}>
                          Delete
                        </button>
                      ) : null}
                      {role === "Admin" ? (
                        <button
                          className="ReuseButton"
                          onClick={handleDownloadSource}
                        >
                          Source File
                        </button>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default ViewQuestions;

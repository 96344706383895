import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./layout.css";
import Navbar from "../Header/Navbar";
import Togglebar from "../SubComponents/Togglebar/Togglebar";
import AddDetails from "../Screens/AddDetails/AddDetails";
import QSetCreator from "../Screens/QSetCreator/QSetCreator";
import QSetMap from "../Screens/QSetMap/QSetMap";
import QSetView from "../Screens/QSetView/QSetView";
import TranslateQuestions from "../Screens/TranslateQuestions/TranslateQuestions";
import ViewQuestions from "../Screens/ViewQuestions/ViewQuestions";
import UploadQuestions from "../Screens/UploadQuestions/UploadQuestions";
import Migrate from "../Screens/Migrate/Migrate";
import Backlog from "../Screens/Backlog/Backlog";
import MigrateMap from "../Screens/MigrateMap/MigrateMap";
import TopicReport from "../Screens/TopicReport/TopicReport";
import SubTopicReport from "../Screens/SubTopicReport/SubTopicReport";
import QSetReport from "../Screens/QSetReport/QSetReport";
import { clearAll } from "../../api/queReservation";

const switchRoutes = (
  <Switch>
    <Route path="/admin/add-details" component={AddDetails} />
    <Route path="/admin/upload-questions" component={UploadQuestions} />
    <Route path="/admin/q-set-creator" component={QSetCreator} />
    <Route path="/admin/q-set-map" component={QSetMap} />
    <Route path="/admin/q-set-view" component={QSetView} />
    <Route path="/admin/translate-questions" component={TranslateQuestions} />
    <Route path="/admin/view-questions" component={ViewQuestions} />
    <Route path="/admin/migrate" component={Migrate} />
    <Route path="/admin/backlog" component={Backlog} />
    <Route path="/admin/migrate-map" component={MigrateMap} />
    <Route path="/admin/topic-report" component={TopicReport} />
    <Route path="/admin/sub-topic-report" component={SubTopicReport} />
    <Route path="/admin/q-set-report" component={QSetReport} />
    <Redirect from="/admin" to="/admin/upload-questions" />
  </Switch>
);

var i = 0;
var timeStampExpire;
var expiryTime;

function Layout() {
  const [togglebar, setTogglebar] = useState(false);
  const userId = window.localStorage.getItem("userId");

  timeStampExpire = window.localStorage.getItem("expiry");
  if (window.location.pathname !== "/" && i === 0) {
    expiryTime = timeStampExpire - Date.now();
    // console.log(expiryTime, i);
    i = 1;
    if (expiryTime < 0) {
      toast.error("Session has expired. Login again to continue...");
      setTimeout(() => {
        window.localStorage.clear();
        window.location = "/";
      }, 3000);
    } else {
      setTimeout(() => {
        toast.error("Session has expired. Login again to continue...");
        setTimeout(() => {
          window.localStorage.clear();
          window.location = "/";
        }, 3000);
      }, expiryTime);
    }
  }

  useEffect(() => {
    async function clearingReservation() {
      if (window.location.pathname !== "/admin/migrate") {
        let reservedArr = JSON.parse(
          window.localStorage.getItem("reservedArray")
        );
        if (reservedArr && reservedArr.length !== 0) {
          try {
            await clearAll(userId);
            window.localStorage.removeItem("reservedArray");
          } catch (err) {
            if (err.response && err.response.data) {
              console.log("err", err);
              toast.error(err.response.data.message);
            } else {
              toast.error("Server Error");
            }
          }
        }
      }
    }
    clearingReservation();
  });

  if (window.localStorage.getItem("token") === null) {
    return (window.location.href = "/");
  }

  function togglebarHandel() {
    setTogglebar(!togglebar);
  }

  const getRoute = () => {
    return window.location.pathname !== null;
  };

  return (
    <div>
      <div className="layoutContainer">
        <button className="layoutButton" onClick={togglebarHandel}>
          ☰
        </button>
        <Navbar />
      </div>
      <div className={togglebar ? "togglebar" : "togglebarClose"}>
        {togglebar ? <Togglebar path={window.location.pathname} /> : ""}
      </div>
      <div className={togglebar ? "togg" : "toggClose"}>
        {" "}
        {getRoute() ? <div>{switchRoutes}</div> : null}{" "}
      </div>
    </div>
  );
}

export default Layout;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchableDropdown_card_wraper__Zx4Bz {
  display: flex;
  margin-top: 10px;
  gap: 5px;
  flex-wrap: wrap;
}
.searchableDropdown_card_wraper__Zx4Bz > div {
  padding: 8px;
  border: 1px solid gray;
  border-radius: 5px;
  background: #f3f1f166;
}
.searchableDropdown_card_wraper__Zx4Bz span {
  background: #ecebeb;
  padding: 4px 5px;
  border: 1px solid rgb(204, 203, 203);
  border-radius: 5px;
  margin-left: 5px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/Screens/AddDetails/searchableDropdown/searchableDropdown.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,QAAQ;EACR,eAAe;AACjB;AACA;EACE,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,qBAAqB;AACvB;AACA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".card_wraper {\n  display: flex;\n  margin-top: 10px;\n  gap: 5px;\n  flex-wrap: wrap;\n}\n.card_wraper > div {\n  padding: 8px;\n  border: 1px solid gray;\n  border-radius: 5px;\n  background: #f3f1f166;\n}\n.card_wraper span {\n  background: #ecebeb;\n  padding: 4px 5px;\n  border: 1px solid rgb(204, 203, 203);\n  border-radius: 5px;\n  margin-left: 5px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card_wraper": `searchableDropdown_card_wraper__Zx4Bz`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box";
// import { BASE_URL } from "../../config/Api";
import { ToastContainer, toast } from "react-toastify";
// import Axios from "axios";
import { passReset } from "../../../api/login";
import ReactLoading from "react-loading";

const initialValues = {
  email: "",
};
const validationSchema = Yup.object({
  email: Yup.string().required("Email is required"),
});

function ForgotPassword() {
  const [startUpLoad, setStartUpLoad] = useState(false);
  const onSubmit = async (values) => {
    setStartUpLoad(true);
    let email;
    email = values.email;
    var data = { email: email };
    // Axios.post(`${BASE_URL}/mail/sendmail`, {
    //   email: email,
    // })
    await passReset(data).then(
      (response) => {
        console.log(response, "resssssssssssssss");
        toast.success("Mail sent successfully!");
        setStartUpLoad(false);
        if (response.data.status === true) {
          // setTimeout(()=>{
          //   // return window.location = "/"
          //   setPass(false)
          // },3000)
        }
      },
      (error) => {
        toast.error("Enter Correct Email Id");
        if (error.response && error.response.data) {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
        }
        setStartUpLoad(false);
      }
    );
  };

  return (
    <div className="loginContainer">
      {startUpLoad ? (
        <div className="loadingBackground">
          <ReactLoading type="spin" color="#68dff0" className="startLoadSpin" />
        </div>
      ) : null}
      <div>
        <h1 className="loginH1">Forgot Password</h1>
      </div>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => {
            return (
              <div>
                <Form>
                  <Box component="div">
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email Id"
                      className="loginUsername"
                    />
                  </Box>
                  <ErrorMessage name="email" component="div">
                    {(error) => <div className="error">{error}</div>}
                  </ErrorMessage>
                  <button type="submit" className="loginButton">
                    <div>Submit</div>
                  </button>
                </Form>
              </div>
            );
          }}
        </Formik>
      </div>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default ForgotPassword;

import React, { useState } from "react";
import style from "./searchableDropdown.module.css";

const SearchableDropdown = ({
  lable,
  options,
  onSelect,
  display_name,
  display_id,
  value,
  onCancel,
}) => {
  const displayName = display_name;
  const displayId = display_id;

  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  // Filter options based on the search term
  const filteredOptions = options?.filter((option) =>
    option?.[`${displayName}`]
      ?.toLowerCase()
      .includes(searchTerm?.toLowerCase())
  );

  // Handle selecting an option
  const handleSelect = (option) => {
    setSelectedOption(option);
    // setSearchTerm(option);
    setIsOpen(false);
    if (onSelect) {
      onSelect(option?.[`${displayId}`]); // Notify parent component of the selection
    }
  };

  // Handle toggling the dropdown
  const toggleDropdown = () => setIsOpen(!isOpen);

  // Handle closing the dropdown when clicking outside
  const handleBlur = () => {
    setTimeout(() => setIsOpen(false), 200); // Delay to allow click on options
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",

        fontFamily: "Arial, sans-serif",
        marginBottom: "15px",
      }}
    >
      {/* Input Field */}
      <label
        htmlFor="searchInput"
        style={{
          fontSize: "16px",
          marginTop: "8px",
          marginBottom: "5px",
          fontWeight: "500",
          marginRight: "3%",
          width: "20%",
          marginLeft: "9%",
          color: "#797979",
        }}
      >
        {lable}
      </label>
      <div
        style={{
          position: "relative",
          width: "52%",
          marginLeft: "4%",
        }}
      >
        <input
          id="searchInput"
          type="text"
          placeholder="Search or select..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onFocus={toggleDropdown}
          onBlur={handleBlur}
          style={{
            width: "100%",
            padding: "8px 15px 8px 15px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            boxSizing: "border-box",
            color: "#555",
            fontSize: "14px",
          }}
        />

        {/* Dropdown Options */}
        {isOpen && (
          <ul
            style={{
              listStyle: "none",
              margin: "0",
              padding: "0",
              position: "absolute",
              width: "100%",
              maxHeight: "150px",
              overflowY: "auto",
              background: "#fff",
              border: "1px solid #ccc",
              borderRadius: "4px",
              zIndex: "1",
              boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            }}
          >
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => (
                <li
                  key={index}
                  onClick={() => {
                    if (!value?.includes(option)) handleSelect(option);
                  }}
                  style={{
                    padding: "8px",
                    cursor: "pointer",
                    backgroundColor:
                      selectedOption === option ? "#f0f0f0" : "#fff",
                  }}
                >
                  {option?.[`${displayName}`]}
                </li>
              ))
            ) : (
              <li
                style={{
                  padding: "8px",
                  color: "#999",
                }}
              >
                No results found
              </li>
            )}
          </ul>
        )}
        {value?.length > 0 ? (
          <div className={style.card_wraper}>
            {value?.map((i, index) => (
              <div key={index}>
                {i?.[`${displayName}`]}{" "}
                <span
                  onClick={() => {
                    onCancel(i?.[`${display_id}`]);
                  }}
                >
                  x
                </span>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SearchableDropdown;
